import {Component} from '@angular/core';
import {URLS} from '../../shared/urls';
import {GlobalService} from '../../shared/services/global.service';

@Component({
  selector: 'app-games-list-menu-manager',
  templateUrl: './games-list-menu-manager.component.html',
  styleUrls: ['./games-list-menu-manager.component.less'],
})
export class GamesListMenuManagerComponent {
  URLS = URLS;
  GlobalService = GlobalService;

  constructor(public globalService: GlobalService) {
  }

}
