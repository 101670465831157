import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {JwtService} from './jwt.service';
import {Observable} from 'rxjs/index';

@Injectable()
export class ApiService {
  constructor(private http: HttpClient,
              private jwtService: JwtService) {
  }

  private setHeaders(): HttpHeaders {
    const headersConfig = {
      // 'Content-Type': 'multipart/form-data',
      // 'Accept': 'application/json'
    };

    if (this.jwtService.getToken()) {
      headersConfig['Authorization'] = `Token ${this.jwtService.getToken()}`;
    }
    return new HttpHeaders(headersConfig);
  }


  getLocal(path: string): Observable<any> {
    return this.http.get(path);
  }

  get(path: string, params: any = {}): Observable<any> {
    return this.http.get(`${environment.main_url}${environment.api_url}${path}`,
      {
        headers: this.setHeaders(),
        params: params
      });
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(`${environment.main_url}${environment.api_url}${path}`, body, {headers: this.setHeaders()});
  }

  post(path: string, body: Object = {}): Observable<any> {
    // const urlSearchParams: URLSearchParams = new URLSearchParams();
    const formData: FormData = new FormData();
    Object.keys(body).forEach((key) => {
      if (Array.isArray(body[key]) && typeof body[key] !== 'string') {
        body[key].forEach((val) => {
          formData.append(key, val);
        });
      }
      else {
        formData.append(key, body[key]);
      }
    });

    return this.http.post(
      `${environment.main_url}${environment.api_url}${path}`,
      formData,
      {
        headers: this.setHeaders(),
      }
    );
  }

  delete(path): Observable<any> {
    return this.http.delete(
      `${environment.main_url}${environment.api_url}${path}`,
      {headers: this.setHeaders()}
    );
  }
}
