export class Point {
  x: number;
  y: number;
  angle: number;
  data: number;

  constructor(length, index, cx, cy, radius, data) {
    this.angle = Math.PI / (length / 2) * index;
    this.x = cx + Math.sin(this.angle) * (data / 100 * radius);
    this.y = cy + Math.cos(this.angle) * (data / 100 * radius);
  }
}
