import {BrowserModule} from '@angular/platform-browser';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';


import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BackgroundManagerComponent} from './site-manager/background-manager/background-manager.component';
import {BgElementComponent} from './site-manager/background-manager/bg-element/bg-element.component';
import {SiteManagerComponent} from './site-manager/site-manager.component';
import {GamesListMenuManagerComponent} from './site-manager/games-list-menu-manager/games-list-menu-manager.component';
import {StatisticsMenuManagerComponent} from './site-manager/statistics-menu-manager/statistics-menu-manager.component';
import {GameBoxComponent} from './site-manager/games-list-menu-manager/game-box/game-box.component';
import {GameScreenManagerComponent} from './site-manager/game-screen-manager/game-screen-manager.component';

import {ApiService} from './shared/services/api.service';
import {JwtService} from './shared/services/jwt.service';
import {GlobalService} from './shared/services/global.service';
import {environment} from '../environments/environment';
import {RouterModule} from '@angular/router';

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, `${environment.main_url}/media/translations/`, '.json');
}


export const APP_ROUTER: ModuleWithProviders = RouterModule.forRoot([
  {
    path: ':language',
    component: SiteManagerComponent
  },
  {
    path: '**',
    redirectTo: '/' + GlobalService.selectedLanguage.identifier,
    pathMatch: 'full'
  }
], {useHash: true});


@NgModule({
  declarations: [
    AppComponent,
    SiteManagerComponent,
    BackgroundManagerComponent,
    BgElementComponent,
    GamesListMenuManagerComponent,
    StatisticsMenuManagerComponent,
    GameBoxComponent,
    GameScreenManagerComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    APP_ROUTER
  ],
  providers: [ApiService, GlobalService, JwtService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
