/**
 * Created by XamiG on 2/7/2018.
 */
import {environment} from '../../environments/environment';

export class URLS {
  // ICONS
  static LANGUAGE_ICONS_URL = `${environment.main_url}/media/lang_icons/`;


  static GAMES_LIST_MENU_BUTTON_ICON = './assets/icons/games-list-menu-button.png';
  static STATISTICS_MENU_BUTTON_ICON = './assets/icons/statistics-menu-button.png';

  static MINDMAP_HEADER_ICON = './assets/icons/mind-map-icon.png';

  static MINDMAP_OVERALL_ICON = './assets/icons/overall.png';
  static MINDMAP_ANALYTICS_SKILLS_ICON = './assets/icons/analytical-skills.png';
  static MINDMAP_CALCULATION_ICON = './assets/icons/calculation.png';
  static MINDMAP_FLEXIBILITY_ICON = './assets/icons/flexibility.png';
  static MINDMAP_SPEED_ICON = './assets/icons/speed.png';
  static MINDMAP_ACCURACY_ICON = './assets/icons/accuracy.png';

  static PLAY_ICON = './assets/icons/play.png';
  static REPLAY_ICON = './assets/icons/replay.png';
  static NEXT_ICON = './assets/icons/play.png';
}
