/**
 * Created by XamiG on 2/22/2018.
 */
export class UiConfigs {
  iconUrl: string;
  logoUrl: string;
  videoUrl: string;

  gameScreenBgColor: string;
  gameScreenBgUrl: string;

  gameScreenHeaderBgColor: string;
  gameScreenHeaderBgUrl: string;

  gameScreenContentBgColor: string;
  gameScreenContentBgUrl: string;


  get gameScreenBg(): string {
    if (this.gameScreenBgUrl) {
      return `url(${this.gameScreenBgUrl})`;
    }
    else if (this.gameScreenBgColor) {
      return this.gameScreenBgColor;
    }
    else {
      return null;
    }
  }

  get gameScreenHeaderBg(): string {
    if (this.gameScreenHeaderBgUrl) {
      return `url(${this.gameScreenHeaderBgUrl})`;
    }
    else if (this.gameScreenHeaderBgColor) {
      return this.gameScreenHeaderBgColor;
    }
    else {
      return null;
    }
  }

  get gameScreenContentBg(): string {
    if (this.gameScreenContentBgUrl) {
      return `url(${this.gameScreenContentBgUrl})`;
    }
    else if (this.gameScreenContentBgColor) {
      return this.gameScreenContentBgColor;
    }
    else {
      return null;
    }
  }
}
