import { Component} from '@angular/core';
import {URLS} from '../../shared/urls';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {GlobalService} from '../../shared/services/global.service';

@Component({
  selector: 'app-statistics-menu-manager',
  templateUrl: './statistics-menu-manager.component.html',
  styleUrls: ['./statistics-menu-manager.component.less'],
  animations: [
    trigger('showHexagonIconsAnimation', [
      state('void', style({
        opacity: 0,
        transform: 'scale(0)'
      })),
      state('*', style({
        opacity: 1,
        transform: 'scale(1)'
      })),
      transition('void => *',
        animate('300ms {{delay}}ms ease-in'),
        {params: {delay: 0}}),
    ]),
  ]
})
export class StatisticsMenuManagerComponent {
  URLS = URLS;

  rankList: { rank: number, avatar: string, nickname: string, iq: number }[] = [
    {
      rank: 1,
      avatar: 'https://cdn.pixabay.com/photo/2016/08/20/05/38/avatar-1606916_960_720.png',
      nickname: 'Plamen',
      iq: Math.round(Math.random() * 100)
    },
    {
      rank: 2,
      avatar: 'https://cdn.pixabay.com/photo/2016/08/20/05/38/avatar-1606916_960_720.png',
      nickname: 'Teo',
      iq: Math.round(Math.random() * 100)
    },
    {
      rank: 3,
      avatar: 'https://cdn.pixabay.com/photo/2016/08/20/05/38/avatar-1606916_960_720.png',
      nickname: 'Marto',
      iq: Math.round(Math.random() * 100)
    },
    {
      rank: 4,
      avatar: 'https://cdn.pixabay.com/photo/2016/08/20/05/38/avatar-1606916_960_720.png',
      nickname: 'Vasko',
      iq: Math.round(Math.random() * 100)
    },
    {
      rank: 5,
      avatar: 'https://cdn.pixabay.com/photo/2016/08/20/05/38/avatar-1606916_960_720.png',
      nickname: 'Hami',
      iq: Math.round(Math.random() * 100)
    },
    {
      rank: 6,
      avatar: 'https://cdn.pixabay.com/photo/2016/08/20/05/38/avatar-1606916_960_720.png',
      nickname: 'Plamen',
      iq: Math.round(Math.random() * 100)
    },
    {
      rank: 7,
      avatar: 'https://cdn.pixabay.com/photo/2016/08/20/05/38/avatar-1606916_960_720.png',
      nickname: 'Teo',
      iq: Math.round(Math.random() * 100)
    },
    {
      rank: 8,
      avatar: 'https://cdn.pixabay.com/photo/2016/08/20/05/38/avatar-1606916_960_720.png',
      nickname: 'Marto',
      iq: Math.round(Math.random() * 100)
    },
    {
      rank: 9,
      avatar: 'https://cdn.pixabay.com/photo/2016/08/20/05/38/avatar-1606916_960_720.png',
      nickname: 'Vasko',
      iq: Math.round(Math.random() * 100)
    },
    {
      rank: 10,
      avatar: 'https://cdn.pixabay.com/photo/2016/08/20/05/38/avatar-1606916_960_720.png',
      nickname: 'Hami',
      iq: Math.round(Math.random() * 100)
    },
    {
      rank: 11,
      avatar: 'https://cdn.pixabay.com/photo/2016/08/20/05/38/avatar-1606916_960_720.png',
      nickname: 'Plamen',
      iq: Math.round(Math.random() * 100)
    },
    {
      rank: 12,
      avatar: 'https://cdn.pixabay.com/photo/2016/08/20/05/38/avatar-1606916_960_720.png',
      nickname: 'Teo',
      iq: Math.round(Math.random() * 100)
    },
    {
      rank: 13,
      avatar: 'https://cdn.pixabay.com/photo/2016/08/20/05/38/avatar-1606916_960_720.png',
      nickname: 'Marto',
      iq: Math.round(Math.random() * 100)
    },
    {
      rank: 14,
      avatar: 'https://cdn.pixabay.com/photo/2016/08/20/05/38/avatar-1606916_960_720.png',
      nickname: 'Vasko',
      iq: Math.round(Math.random() * 100)
    },
    {
      rank: 15,
      avatar: 'https://cdn.pixabay.com/photo/2016/08/20/05/38/avatar-1606916_960_720.png',
      nickname: 'Hami',
      iq: Math.round(Math.random() * 100)
    }
  ];


  constructor(public globalService: GlobalService) {
  }

  trackByFn(index) {
    return index;
  }


}
