import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {GlobalService} from '../../shared/services/global.service';
import {URLS} from '../../shared/urls';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-game-screen-manager',
  templateUrl: './game-screen-manager.component.html',
  styleUrls: ['./game-screen-manager.component.less'],
  animations: [
    trigger('createAnimationOpacity', [
      state('void', style({
        transform: 'scale(0.8,0.8)',
        opacity: 0,
      })),
      state('*', style({
        transform: 'scale(1,1)',
        opacity: 1,
      })),
      transition('void => *', animate('120ms 120ms linear')),
      transition('* => void', animate('120ms linear')),
    ]),
  ]
})
export class GameScreenManagerComponent implements OnInit {
  URLS = URLS;

  @ViewChild('gameScreen') gameScreen: ElementRef;
  @ViewChild('previewVideo') previewVideo: ElementRef;


  constructor(public globalService: GlobalService) {

  }


  ngOnInit() {
    this.globalService.gameScreenIframeElRef = this.gameScreen;
  }

  /* ON MESSAGE RECEIVE FROM THE IFRAME GAME*/
  @HostListener('window:message', ['$event'])
  onMessageReceive(event) {
    this.globalService.onMessageReceive(event);
  }

  onPlayClick(): void {
    this.globalService.gameScreenIframe.src = this.globalService.selectedGame.linkUrl;
  }

  onPreviewVideoClick(): void {
    if (this.previewVideo.nativeElement.paused) {
      this.previewVideo.nativeElement.play();
    }
    else {
      this.previewVideo.nativeElement.pause();
    }
  }

  onNextDailyChallengeClick(): void {
    const games = this.globalService.games;
    const selectedGame = this.globalService.selectedGame;
    if (this.canClickNextButton()) {
      this.globalService.selectGame(games[games.indexOf(selectedGame) + 1]);
    }
  }

  canClickNextButton(): boolean {
    const games = this.globalService.games;
    const selectedGame = this.globalService.selectedGame;
    return games.indexOf(selectedGame) + 1 < games.length;
  }


}
