/**
 * Created by XamiG on 2/27/2018.
 */

export class IqConfigs {
  maxIq: number;
  speedWeight: number;
  accuracyWeight: number;
  analyticalSkillsWeight: number;
  calculationWeight: number;
}
