import {Component, HostListener, OnInit} from '@angular/core';
import {animate, animateChild, keyframes, query, state, style, transition, trigger} from '@angular/animations';
import {GlobalService} from '../shared/services/global.service';
import {CONSTANTS} from '../shared/constants';
import {URLS} from '../shared/urls';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-site-manager',
  templateUrl: './site-manager.component.html',
  styleUrls: ['./site-manager.component.less'],
  animations: [
    trigger('screenState', [
      state('*,center-shown', style({
        transform: 'translateX(0)',
      })),
      state('left-shown', style({
        transform: 'translateX(75%)',
      })),
      state('right-shown', style({
        transform: 'translateX(-75%)',
      })),
      transition('* <=> *', animate('600ms 100ms cubic-bezier(0.680, -0.550, 0.265, 1.550)')),
      transition('* <=> *',
        [
          query('@*', [animateChild()], {optional: true}),
        ]),
    ]),
    trigger('createAnimationFromLeft', [
      state('void', style({
        transform: 'translateX(-100%)',
        opacity: 0
      })),
      transition('void => false', animate('0ms linear')),
      transition('void => true',
        animate('1000ms cubic-bezier(0.430, 0.695, 0.315, 1.250)', keyframes([
          style({transform: 'translateX(-100%)', opacity: 0, offset: 0}),
          style({transform: 'translateX(-100%)', opacity: 0.1, offset: 0.4}),
          style({transform: 'translateX(0)', opacity: 1, offset: 1})
        ]))),
    ]),
    trigger('createAnimationFromCenter', [
      state('void', style({
        opacity: 0
      })),
      transition('void => *',
        animate('1000ms cubic-bezier(0.430, 0.695, 0.315, 1.250)'))
    ]),
    trigger('createAnimationFromRight', [
      state('void', style({
        transform: 'translateX(100%)',
        opacity: 0
      })),
      transition('void => false', animate('0ms linear')),
      transition('void => true',
        animate('1000ms cubic-bezier(0.430, 0.695, 0.315, 1.250)', keyframes([
          style({transform: 'translateX(100%)', opacity: 0, offset: 0}),
          style({transform: 'translateX(100%)', opacity: 0.1, offset: 0.4}),
          style({transform: 'translateX(0)', opacity: 1, offset: 1})
        ])))
    ]),
    trigger('createAnimationFromTop', [
      state('void', style({
        transform: 'translate(-50%,-100%)',
        opacity: 0
      })),
      transition('* => *',
        animate('300ms cubic-bezier(0.430, 0.695, 0.315, 1.250)'))
    ]),
  ]
})
export class SiteManagerComponent implements OnInit {
  GlobalService = GlobalService;

  URLS = URLS;


  private swipeCoord?: [number, number];
  private swipeTime?: number;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              public globalService: GlobalService,
              private translationService: TranslateService) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.onWindowResize();
      this.globalService.getAllLanguages()
        .subscribe(
          data => {
            GlobalService.languages = data;

            this.activatedRoute.params.subscribe(params => {
              setTimeout(() => {
                const languageFromUrl = GlobalService.languages.find(lang => lang.identifier === params['language']);
                const defaultLang = GlobalService.languages.find(lang => lang.identifier === 'default');

                if (params['language'] && !!languageFromUrl) {
                  GlobalService.selectedLanguage = languageFromUrl;
                }
                else if (!!defaultLang) {
                  GlobalService.selectedLanguage = defaultLang;
                  this.router.navigate(['/', GlobalService.selectedLanguage.identifier]);
                }
                else {
                  GlobalService.selectedLanguage = GlobalService.languages[0];
                  this.router.navigate(['/', GlobalService.selectedLanguage.identifier]);
                }


                this.translationService.setDefaultLang(GlobalService.selectedLanguage.identifier);
                this.translationService.use(GlobalService.selectedLanguage.identifier);
                this.translationService.get('gamesLocalizationObject')
                  .subscribe(gamesLocalizationObject => {
                    this.globalService.gamesLocalizationObject = gamesLocalizationObject;
                  });
              });
            });
          }
        );
    });
  }

  @HostListener('window:touchstart', ['$event'])
  @HostListener('window:mousedown', ['$event'])
  onSiteClick(event) {
    if (this.globalService.isDeviceMobile) {
      this.globalService.requestFullScreen();
    }
  }


  @HostListener('window:touchstart', ['$event'])
  onSwipeStart(e: any): void {
    const coord: [number, number] = [e.changedTouches[0].pageX, e.changedTouches[0].pageY];
    const time = new Date().getTime();

    this.swipeCoord = coord;
    this.swipeTime = time;
  }

  @HostListener('window:touchend', ['$event'])
  onSwipeEnd(e: any): void {
    const coord: [number, number] = [e.changedTouches[0].pageX, e.changedTouches[0].pageY];
    const time = new Date().getTime();

    const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
    const duration = time - this.swipeTime;

    if (duration < 1000 &&
      Math.abs(direction[0]) > window.innerWidth / 10 &&
      Math.abs(direction[0]) > Math.abs(direction[1] * 3)) {
      const swipe = direction[0] < 0 ? 'next' : 'previous';
      // Do whatever you want with swipe

      const animationStates = ['left-shown', 'center-shown', 'right-shown'];

      if (swipe === 'next') {
        if (this.globalService.screenAnimationState !== animationStates[2]) {
          this.globalService.screenAnimationState = animationStates[animationStates.indexOf(this.globalService.screenAnimationState) + 1];
        }
      }
      else {
        if (this.globalService.screenAnimationState !== animationStates[0]) {
          this.globalService.screenAnimationState = animationStates[animationStates.indexOf(this.globalService.screenAnimationState) - 1];
        }
      }

    }

  }


  onScreenAnimationDone(): void {
    this.globalService.canChangeScreenState = true;
    if (!this.globalService.isWindowDesktop &&
      this.globalService.screenAnimationState === 'center-shown') {
      this.globalService.shouldShowMenus = false;
    }
  }


  onWindowResize(): void {
    this.globalService.isWindowDesktop = window.innerWidth / window.innerHeight >= CONSTANTS.MIN_DESKTOP_RATIO;

    if (this.globalService.isWindowDesktop) {
      this.globalService.shouldShowMenusButtons = false;
      if (this.globalService.screenAnimationState !== 'center-shown') {
        this.clickMenuButton('center-shown');
      }
      setTimeout(() => {
        this.globalService.shouldShowMenus = true;
      });
    }
    else {
      this.globalService.shouldShowMenusButtons = true;
    }
  }

  clickMenuButton(newState: string): void {
    // this.backgroundManager.resetAllBgElements();
    this.globalService.changeScreenAnimationState(newState);
  }

  onLanguageClick(languageId: string): void {
    this.router.navigate(['/', languageId]);
    setTimeout(() => {
      this.globalService.isLanguagePopupShown = false;
    }, 300);
  }

}
