import {Component, Input, OnInit} from '@angular/core';
import {DailyChallengeGame} from '../../../shared/models/daily-challenge-game.model';
import {GlobalService} from '../../../shared/services/global.service';

@Component({
  selector: 'app-game-box',
  templateUrl: './game-box.component.html',
  styleUrls: ['./game-box.component.less'],

})
export class GameBoxComponent implements OnInit {
  @Input() game: DailyChallengeGame;
  gameScore: {
    gameIdentifier: string,
    modeIdentifier: string,
    iq: number,
    statistics: {
      speed: number,
      accuracy: number,
      analyticalSkills: number,
      calculation: number
    }
  };

  constructor(private globalService: GlobalService) {
  }

  ngOnInit(): void {
    this.gameScore = this.globalService.user.gameScores
      .find(game => game.gameIdentifier === this.game.gameIdentifier &&
        game.modeIdentifier === this.game.modeIdentifier);
  }
}
