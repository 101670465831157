/**
 * Created by XamiG on 2/7/2018.
 */
export class SharedFunctions {


  static serializeToTypeScriptConvention(obj: any, shouldSerializeInDepth: boolean = false): any {
    // Assign the object with the new property names
    let newObj: any;

    // Check if the obj is an Array
    if (obj instanceof Array) {
      newObj = [];
      obj.forEach(el => {
        newObj.push(SharedFunctions.serializeToTypeScriptConvention(el, shouldSerializeInDepth));
      });
    }
    // Check if the obj is an Object
    else if (obj instanceof Object) {
      newObj = {};
      Object.keys(obj).forEach(key => {
        // Create the new name of the current property
        let newKey = '';
        for (let i = 0; i < key.length; i++) {
          if (key[i] === '_') {
            i++;
            if (i < key.length) {
              newKey += key[i].toUpperCase();
            }
          }
          else {
            newKey += key[i];
          }
        }
        // Go in depth if needed
        if (shouldSerializeInDepth) {
          newObj[newKey] = SharedFunctions.serializeToTypeScriptConvention(obj[key], shouldSerializeInDepth);
        }
        else {
          newObj[newKey] = obj[key];
        }
      });
    }
    // Else return it
    else {
      newObj = obj;
    }
    return newObj;
  }

  static sortNumbers(a: number, b: number) {
    if (a > b) {
      return 1;
    }
    if (a < b) {
      return -1;
    }
    return 0;
  }


  static sortStrings(a: string, b: string) {
    const nameA = a.toUpperCase(); // ignore upper and lowercase
    const nameB = b.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  static sortDate(a: string, b: string) {
    return SharedFunctions.sortNumbers(Date.parse(a), Date.parse(b));
  }

  static bulkCreateSerializersToModels<T, U>(serializerArray: Array<T>, model: { new(args: Object): U; }): Array<U> {
    const result = [];
    if (serializerArray) {
      serializerArray.forEach((element) => {
        result.push(new model(element));
      });
    }
    return result;
  }

  static getRandomNumberFromTo(from: number, to: number): number {
    return Math.random() * (to - from) + from;
  }

  static getRandomElement(arr: any[]): any {
    return arr[Math.floor(Math.random() * arr.length)];
  }

  static getRandomDate(fromDate: string, toDate: string): Date {
    const from = Date.parse(fromDate);
    const to = Date.parse(toDate);
    return new Date(from + Math.random() * (to - from));
  }

  static bulkCreateRandomObj(model: any, arrCount: number): any[] {
    const result = [];
    for (let i = 0; i < arrCount; i++) {
      const obj = {};
      Object.keys(model).forEach(key => {
        obj[key] = model[key]();
      });
      result.push(obj);
    }
    return result;
  }

  static toSlug(str: string): string {
    return str.toLowerCase().replace(/\s+/g, '-');
  }

  static generateRandomString(length: number): string {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  static getLocaleDateTimeFromMilliSeconds(dateInMilliSeconds: number): string {
    const dateTime = new Date(dateInMilliSeconds);
    const dateOptions = {day: '2-digit', month: 'long', year: 'numeric'};
    const date = dateTime.toLocaleDateString(['en-US'], dateOptions);
    const timeOptions = {hour: '2-digit', minute: '2-digit', hour12: false};
    const time = dateTime.toLocaleTimeString(['en-US'], timeOptions);
    return date + ' ' + time;
  }

  static calculateSuccessRatio(wins: number, losses: number): number {
    const totalGamesPlayed = wins + losses;
    if (totalGamesPlayed) {
      return parseFloat((wins / (totalGamesPlayed) * 100).toFixed(1));
    }
    else {
      return 0;
    }
  }
}
