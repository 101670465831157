import {ElementRef, Injectable} from '@angular/core';
import * as FullScreenApi from 'screenfull';
import {ApiService} from './api.service';
import {DailyChallengeGame} from '../models/daily-challenge-game.model';
import {DailyChallengeUser} from '../models/daily-challenge-user.model';
import {ShapeController} from '../../site-manager/statistics-menu-manager/hexagon/ShapeController';
import {Observable} from 'rxjs/index';

@Injectable()
export class GlobalService {
  static languages: { identifier: string, value: string }[];
  static selectedLanguage: { identifier: string, value: string } = {identifier: 'EN', value: 'English'};

  isWindowDesktop: boolean;
  isDeviceMobile: boolean;
  // isDeviceIos: boolean;

  isSiteReadyToShow: boolean;

  isCurrentlyInGame: boolean;
  shouldShowGameScreenInfo: boolean;


  gamesLocalizationObject: any;

  hexagonShape: ShapeController;
  shouldShowHexagonIcons: boolean;

  user: DailyChallengeUser;

  games: DailyChallengeGame[];
  selectedGame: DailyChallengeGame;
  selectedGameScore: {
    gameIdentifier: string,
    modeIdentifier: string,
    iq: number,
    statistics: {
      speed: number,
      accuracy: number,
      analyticalSkills: number,
      calculation: number
    }
  };

  gameScreenIframeElRef: ElementRef;

  screenAnimationState: string;
  canChangeScreenState: boolean;

  isLanguagePopupShown: boolean;


  shouldShowMenus: boolean;
  shouldShowMenusButtons: boolean;

  get gameScreenIframe(): any {
    return this.gameScreenIframeElRef.nativeElement;
  }


  constructor(private apiService: ApiService) {
    this.canChangeScreenState = true;
    this.screenAnimationState = 'center-shown';

    this.games = [];
    GlobalService.languages = [];


    this.isDeviceMobile = (/android|webos|iphone|ipad|ipod|blackberry|windows phone/)
      .test(navigator.userAgent.toLowerCase());
    // this.isDeviceIos = (/webos|iphone|ipad|ipod/)
    //   .test(navigator.userAgent.toLowerCase());


    // this.apiService.getLocal('./assets/ALL_DC_GAMES.json')
    // this.apiService.getLocal('./assets/DC_GAMES.json')
    this.getAllDailyChallengeGames()
      .subscribe(data => {
        data.daily_challenge.forEach(challenge => {
          const dailyChallenge = DailyChallengeGame.create(challenge);
          this.games.push(dailyChallenge);
        });
        this.user = new DailyChallengeUser(this.games);
        this.isSiteReadyToShow = true;

        this.selectGame(this.games[0]);

        this.shouldShowHexagonIcons = true;

        setTimeout(() => {
          const hexagonStats = this.calculateMindMapStatistics();
          // console.log(hexagonStats);
          this.hexagonShape = new ShapeController('hexagon-canvas', 300, 40,
            [
              [
                hexagonStats.flexibility,
                hexagonStats.calculation,
                hexagonStats.analyticalSkills,
                hexagonStats.iq,
                hexagonStats.accuracy,
                hexagonStats.speed
              ]
            ]);
          // console.log(this.hexagonShape);
        });
      });
  }

  onMessageReceive(event): void {
    const message = event.data;

    switch (message.type) {
      case 'CONTENT_READY':
        // Content is loaded and we need the data to start the mode
        this.postGameConfirmOriginToIframe();
        this.postGameInitialDataToIframe();
        break;
      case 'GAME_INITIALISED':
        // Game is initialised and waits for unpause
        this.isCurrentlyInGame = true;
        setTimeout(() => {
          this.postUnpauseGameToIframe();
        });
        break;
      case 'CHALLENGE_DONE':

        const gameFromStatistics = this.games.find(game =>
          game.gameIdentifier === message.data.gameIdentifier &&
          game.modeIdentifier === message.data.modeIdentifier
        );

        const gameScore = {
          gameIdentifier: gameFromStatistics.gameIdentifier,
          modeIdentifier: gameFromStatistics.modeIdentifier,
          iq: gameFromStatistics.calculateIq(message.data.statistics),
          statistics: message.data.statistics
        };

        if (this.selectedGame === gameFromStatistics) {
          this.isCurrentlyInGame = false;
          this.gameScreenIframe.src = '';
        }
        this.user.updateScore(gameScore);
        setTimeout(() => {
          const hexagonStats = this.calculateMindMapStatistics();
          // console.log(hexagonStats);
          this.hexagonShape.changeData(0, [
            hexagonStats.flexibility,
            hexagonStats.calculation,
            hexagonStats.analyticalSkills,
            hexagonStats.iq,
            hexagonStats.accuracy,
            hexagonStats.speed
          ]);
        });
        break;
    }
  }

  postGameConfirmOriginToIframe(): void {
    const message = {
      type: 'CONFIRM_DC_ORIGIN',
    };
    this.gameScreenIframe.contentWindow.postMessage(message, '*');
  }

  postGameInitialDataToIframe(): void {
    const data = {
      gameIdentifier: this.selectedGame.gameIdentifier,
      modeIdentifier: this.selectedGame.modeIdentifier,
      generatedGameParams: this.selectedGame.generatedGameParams
    };
    const message = {
      type: 'MODE_DATA',
      data: data,
      translations: this.gamesLocalizationObject
    };
    this.gameScreenIframe.contentWindow.postMessage(message, '*');
  }

  postUnpauseGameToIframe(): void {
    const message = {
      type: 'UNPAUSE_GAME',
    };
    // console.log(message);
    this.gameScreenIframe.contentWindow.postMessage(message, '*');
  }

  postRequestStatisticsToIframe(): void {
    const message = {
      type: 'SEND_STATISTICS',
    };
    this.gameScreenIframe.contentWindow.postMessage(message, '*');
  }

  selectGame(newSelectedGame: DailyChallengeGame): void {
    if (this.selectedGame !== newSelectedGame) {
      if (this.isCurrentlyInGame) {
        this.postRequestStatisticsToIframe();
        this.gameScreenIframe.src = '';
      }
      this.shouldShowGameScreenInfo = false;
      this.selectedGame = newSelectedGame;
      this.selectedGameScore = this.user.gameScores
        .find(game => game.gameIdentifier === this.selectedGame.gameIdentifier &&
          game.modeIdentifier === this.selectedGame.modeIdentifier);
      this.isCurrentlyInGame = false;
      setTimeout(() => {
        setTimeout(() => {
          this.changeScreenAnimationState('center-shown');
        });
        this.shouldShowGameScreenInfo = true;
      });
    }
  }


  requestFullScreen(elementRef: HTMLElement = document.body): void {
    if (FullScreenApi.enabled) {
      FullScreenApi.request(elementRef);
    }
  }

  getAllLanguages() {
    return this.apiService.get('/localization/alllangs/');
  }

  getAllDailyChallengeGames(): Observable<any> {
    // THIS IS USED TO TEST ALL GAMES
    // return this.apiService.get('/dailychallenge/getall/');
    // TODO: FIX THIS LINE ! IT WAS ONLY FOR TESTING !
    return this.apiService.get('/dailychallenge/data/');
  }


  changeScreenAnimationState(newState: string): void {
    if (this.canChangeScreenState) {
      if (this.screenAnimationState !== newState) {
        this.canChangeScreenState = false;
      }
      if (this.screenAnimationState === newState) {
        this.screenAnimationState = 'center-shown';
      }
      else {
        this.screenAnimationState = newState;
        if (!this.isWindowDesktop) {
          this.shouldShowMenus = true;
        }
      }
    }
  }

  calculateMindMapStatistics() {
    // debugger;

    let maxSpeedIq = 0;
    let maxAccuracyIq = 0;
    let maxAnalyticalSkillsIq = 0;
    let maxCalculationIq = 0;
    let maxIq = 0;

    this.games.forEach(game => {
        maxSpeedIq += (game.iqConfigs.speedWeight / 100) * game.iqConfigs.maxIq;
        maxAccuracyIq += (game.iqConfigs.accuracyWeight / 100) * game.iqConfigs.maxIq;
        maxAnalyticalSkillsIq += (game.iqConfigs.analyticalSkillsWeight / 100) * game.iqConfigs.maxIq;
        maxCalculationIq += (game.iqConfigs.calculationWeight / 100) * game.iqConfigs.maxIq;

        maxIq += game.iqConfigs.maxIq;
      }
    );

    let currentSpeedIq = 0;
    let currentAccuracyIq = 0;
    let currentAnalyticalSkillsIq = 0;
    let currentCalculationIq = 0;
    let currentIq = 0;
    const flexibilityIqs = [];

    this.games.forEach(game => {
      const gameScore = this.user.gameScores.find(score => game.gameIdentifier === score.gameIdentifier && game.modeIdentifier === score.modeIdentifier);
      const gameScoreIq = game.calculateIq(gameScore.statistics) / 100;
      if (gameScoreIq > 0) {
        if (game.iqConfigs.speedWeight) {
          currentSpeedIq += (gameScore.statistics.speed / 100) * (game.iqConfigs.speedWeight / 100) * game.iqConfigs.maxIq;
        }
        if (game.iqConfigs.accuracyWeight) {
          currentAccuracyIq += (gameScore.statistics.accuracy / 100) * (game.iqConfigs.accuracyWeight / 100) * game.iqConfigs.maxIq;
        }
        if (game.iqConfigs.analyticalSkillsWeight) {
          currentAnalyticalSkillsIq += (gameScore.statistics.analyticalSkills / 100) * (game.iqConfigs.analyticalSkillsWeight / 100) * game.iqConfigs.maxIq;
        }
        if (game.iqConfigs.calculationWeight) {
          currentCalculationIq += ((gameScore.statistics.calculation / 100) * (game.iqConfigs.calculationWeight / 100)) * game.iqConfigs.maxIq;
        }

        currentIq += gameScoreIq;
      }
      flexibilityIqs.push((gameScoreIq / (game.iqConfigs.maxIq / 100)) / 100);
    });

    const speed = maxSpeedIq ? Math.round((currentSpeedIq / maxSpeedIq) * 100) : 0;
    const accuracy = maxAccuracyIq ? Math.round((currentAccuracyIq / maxAccuracyIq) * 100) : 0;
    const analyticalSkills = maxAnalyticalSkillsIq ? Math.round((currentAnalyticalSkillsIq / maxAnalyticalSkillsIq) * 100) : 0;
    const calculation = maxCalculationIq ? Math.round((currentCalculationIq / maxCalculationIq) * 100) : 0;
    const iq = maxIq ? Math.round((currentIq / maxIq) * 100) : 0;
    const flexibility = calculateFlexibility(flexibilityIqs) * 100;

    return {
      speed: speed,
      accuracy: accuracy,
      analyticalSkills: analyticalSkills,
      calculation: calculation,
      iq: iq,
      flexibility: flexibility
    };


    function calculateFlexibility(flexibilities: number[]) {
      const gameIqs = flexibilities;
      const gameCount = flexibilities.length;
      let flex = 0;
      let mean = 0;
      let variance = 0;
      if (gameCount > 0) {
        for (let i = 0; i < gameCount; i++) {
          mean += gameIqs[i];
        }
        mean /= gameCount;
        if (mean > 0) {

          for (let i = 0; i < gameCount; i++) {
            variance += Math.abs(gameIqs[i] - mean);
          }
          variance /= gameCount;

          flex = mean - variance * mean;
        }
        else {
          flex = 0;
        }
        return Math.round(flex * 100) / 100;
      }
    }

  }
}
