import {Shape} from './Shape';
import {StatType} from './StatType';
import {CONSTANTS} from '../../../shared/constants';


export class ShapeController {
  canvas: any;
  ctx: any;
  canvasSize: number;
  shapes: Array<Shape>;
  data: Array<Shape>;
  dataTypes: Array<Array<StatType>>;

  constructor(canvasID: string, canvasSize: number, padding: number, dataTypes) {
    this.canvas = document.getElementById(canvasID);
    this.canvas.width = canvasSize;
    this.canvas.height = canvasSize;
    this.ctx = this.canvas.getContext('2d');
    this.canvasSize = canvasSize;
    const radius = this.canvasSize / 2 - padding;
    const sides = dataTypes[0].length;
    const cx = this.canvasSize / 2;
    const cy = this.canvasSize / 2;

    this.shapes = [];
    this.data = [];
    this.dataTypes = dataTypes;

    this.createShapes(CONSTANTS.SHAPES_FILL.length, sides, cx, cy, radius);
    this.createData(this.dataTypes.length, sides, cx, cy, radius);

    for (let i = 0; i < this.shapes.length; i++) {
      const shape = this.shapes[i];
      for (let j = 0; j < shape.points.length; j++) {
        TweenMax.from(shape.points[j], Math.random() * 0.75 + 1, {
          x: this.canvasSize / 2,
          y: this.canvasSize / 2,
          ease: Elastic.easeOut
        });
      }
    }

    for (let i = 0; i < this.data.length; i++) {
      const shape = this.data[i];
      for (let j = 0; j < shape.points.length; j++) {
        TweenMax.from(shape.points[j], Math.random() * 0.75 + 0.25, {
          x: this.canvasSize / 2,
          y: this.canvasSize / 2,
          delay: 1 + 0.2 * i,
          ease: Back.easeOut
        });
      }
    }
    let indexHexagon;
    const timer = setInterval(() => {
      this.loop(cx, cy);
      if (indexHexagon >= 200) {
        clearInterval(timer);
      }
      indexHexagon++;
    }, 1000 / 60);
  }

  createShapes(numberOfShapes, sides, cx, cy, radius) {
    for (let i = 0; i < numberOfShapes; i++) {
      this.shapes.push(new Shape(sides, cx, cy, radius * (numberOfShapes - i) / numberOfShapes));
    }
  }

  drawShapes(centerX, centerY) {
    this.ctx.clearRect(0, 0, this.canvasSize, this.canvasSize);

    for (let k = 0; k < this.shapes.length; k++) {
      const shape = this.shapes[k];
      this.ctx.beginPath();
      this.ctx.lineTo(shape.points[0].x, shape.points[0].y);
      for (let i = 0; i < shape.points.length; i++) {
        this.ctx.lineTo(shape.points[i].x, shape.points[i].y);
      }

      this.ctx.fillStyle = CONSTANTS.SHAPES_FILL[k];
      this.ctx.strokeStyle = CONSTANTS.SHAPES_STROKE[k];
      this.ctx.lineWidth = CONSTANTS.LINE_WIDTHS[k];
      this.ctx.closePath();
      this.ctx.fill();
      this.ctx.stroke();
    }

    for (let m = 0; m < this.shapes[0].points.length; m++) {
      this.ctx.strokeStyle = CONSTANTS.STROKE_BETWEEN_SECTORS;
      this.ctx.lineWidth = CONSTANTS.LINE_WIDTH_BETWEEN_SECTORS;
      this.ctx.moveTo(centerX, centerY);
      this.ctx.lineTo(this.shapes[0].points[m].x, this.shapes[0].points[m].y);
      this.ctx.stroke();
    }
  }

  createData(numberOfShapes, sides, cx, cy, radius) {
    for (let i = 0; i < numberOfShapes; i++) {
      const currentData = [];
      for (let j = 0; j < this.dataTypes[0].length; j++) {
        currentData.push((this.dataTypes[i][j]));
      }
      this.data.push(new Shape(sides, cx, cy, radius, currentData));
    }
  }

  drawDataShapes() {
    for (let i = 0; i < this.data.length; i++) {
      const shape = this.data[i];
      this.ctx.beginPath();
      this.ctx.lineTo(shape.points[0].x, shape.points[0].y);

      for (let j = 0; j < shape.points.length; j++) {
        this.ctx.lineTo(shape.points[j].x, shape.points[j].y);
      }

      this.ctx.fillStyle = CONSTANTS.DATA_FILL[i];
      this.ctx.strokeStyle = CONSTANTS.DATA_STROKE[i];
      this.ctx.lineWidth = CONSTANTS.DATA_LINE_WIDTH;

      this.ctx.closePath();
      this.ctx.fill();
      this.ctx.stroke();
    }
  }

  loop(centerX, centerY) {
    this.drawShapes(centerX, centerY);
    this.drawDataShapes();

  }

  changeData(index, newData) {
    const oldData = this.data[index].points;
    this.data[index].points = [];
    this.data[index].distributePoints(this.dataTypes[0].length, newData);
    const shape = this.data[index];
    for (let j = 0; j < shape.points.length; j++) {
      TweenMax.from(shape.points[j], Math.random() * 0.75 + 0.25, {
        x: oldData[j].x,
        y: oldData[j].y,
        ease: Back.easeOut
      });
    }
  }
}

