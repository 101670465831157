/**
 * Created by XamiG on 2/27/2018.
 */
import {CONSTANTS} from '../constants';

export class DailyChallengeUser {
  gameScores: {
    gameIdentifier: string,
    modeIdentifier: string,
    iq: number,
    statistics: {
      speed: number,
      accuracy: number,
      analyticalSkills: number,
      calculation: number
    }
  }[];

  constructor(dailyChallenges: { gameIdentifier: string, modeIdentifier: string }[]) {
    const date = localStorage.getItem('gameScoresDate');
    if (date) {
      if (new Date(parseFloat(date)).getDate() !== new Date(Date.now()).getDate()) {
        localStorage.clear();
      }
    }

    this.gameScores = [];
    dailyChallenges.forEach(dailyChallenge => {
      const gameScore = {
        gameIdentifier: dailyChallenge.gameIdentifier,
        modeIdentifier: dailyChallenge.modeIdentifier,
        iq: 0,
        statistics: {
          speed: 0,
          accuracy: 0,
          analyticalSkills: 0,
          calculation: 0
        }
      };
      this.gameScores.push(gameScore);
    });
    const savedGameScores = localStorage.getItem('gameScores');
    if (savedGameScores) {
      const savedGameScoresArr = JSON.parse(savedGameScores);
      savedGameScoresArr.forEach(savedGameScore => {
        const currentGameScore = this.gameScores.find(gameScore =>
          gameScore.gameIdentifier === savedGameScore.gameIdentifier &&
          gameScore.modeIdentifier === savedGameScore.modeIdentifier);

        currentGameScore.iq = savedGameScore.iq;
        currentGameScore.statistics = savedGameScore.statistics;
      });
    }
  }


  updateScore(newGameScore: {
    gameIdentifier: string,
    modeIdentifier: string,
    iq: number,
    statistics: {
      speed: number,
      accuracy: number,
      analyticalSkills: number,
      calculation: number
    }
  }) {
    const gameScore = this.gameScores.find((score) =>
      score.gameIdentifier === newGameScore.gameIdentifier &&
      score.modeIdentifier === newGameScore.modeIdentifier);

    const oldIq = gameScore.iq;
    if (oldIq < newGameScore.iq) {

      gameScore.statistics.speed = newGameScore.statistics.speed * 100;
      gameScore.statistics.accuracy = newGameScore.statistics.accuracy * 100;
      gameScore.statistics.analyticalSkills = newGameScore.statistics.analyticalSkills * 100;
      gameScore.statistics.calculation = newGameScore.statistics.calculation * 100;

      gameScore.iq = newGameScore.iq;
      localStorage.setItem('gameScores', JSON.stringify(this.gameScores));
      localStorage.setItem('gameScoresDate', Date.now().toString());
      gameScore.iq = oldIq;

      TweenMax.to(gameScore, CONSTANTS.IQ_TWEEN_MILLISECONDS / 1000, {
        iq: newGameScore.iq,
        ease: Circ.easeOut
      }).eventCallback('onComplete', () => {
        TweenMax.killTweensOf(gameScore);
      });
    }
  }

}
