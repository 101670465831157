import {ChangeDetectionStrategy, Component, HostListener, OnInit, QueryList, ViewChildren} from '@angular/core';
import {SharedFunctions} from '../../shared/shared-functions';
import {BgElementComponent} from './bg-element/bg-element.component';
import {GlobalService} from '../../shared/services/global.service';

@Component({
  selector: 'app-background-manager',
  templateUrl: './background-manager.component.html',
  styleUrls: ['./background-manager.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackgroundManagerComponent implements OnInit {
  bgElements: { x: number, y: number, scale: number }[];

  bigBgElements: { x: number, y: number, scale: number }[];
  mediumBgElements: { x: number, y: number, scale: number }[];
  smallBgElements: { x: number, y: number, scale: number }[];


  @ViewChildren(BgElementComponent) bgElementsComponents: QueryList<BgElementComponent>;
  bigBgElementsLayerMargin: string;
  mediumBgElementsLayerMargin: string;
  smallBgElementsLayerMargin: string;

  constructor(private globalService: GlobalService) {
    this.bgElements = [];
    this.bigBgElements = [];
    this.mediumBgElements = [];
    this.smallBgElements = [];
  }

  ngOnInit() {
    const randomBgElementsCount = Math.floor(SharedFunctions.getRandomNumberFromTo(10, 16));
    for (let i = 0; i < randomBgElementsCount; i++) {
      const randomX = SharedFunctions.getRandomNumberFromTo(0, 100);
      const randomY = SharedFunctions.getRandomNumberFromTo(0, 100);
      const randomScale = SharedFunctions.getRandomNumberFromTo(0.4, 1);
      const bgEl = {x: randomX, y: randomY, scale: randomScale, margin: ''};
      this.bgElements.push(bgEl);
    }
    this.bgElements.sort((a, b) => {
      return SharedFunctions.sortNumbers(b.scale, a.scale);
    });
    this.bigBgElements = this.bgElements.filter((bgEl) => bgEl.scale > 0.8) || [];
    this.mediumBgElements = this.bgElements.filter((bgEl) => bgEl.scale > 0.6 && bgEl.scale <= 0.8) || [];
    this.smallBgElements = this.bgElements.filter((bgEl) => bgEl.scale <= 0.6) || [];

    setInterval(() => {
      this.resetAllBgElements();
    }, 10000);
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(e) {
    if (!this.globalService.isDeviceMobile) {
      const offsetX = (e.clientX - window.innerWidth / 2) / window.innerWidth;
      const offsetY = (e.clientY - window.innerHeight / 2) / window.innerHeight;

      this.bigBgElementsLayerMargin = -offsetY * 5 + 'vh' + ' ' + -offsetX * 5 + 'vw';
      this.mediumBgElementsLayerMargin = -offsetY * 10 + 'vh' + ' ' + -offsetX * 10 + 'vw';
      this.smallBgElementsLayerMargin = -offsetY * 15 + 'vh' + ' ' + -offsetX * 15 + 'vw';
    }
  }


  resetAllBgElements(): void {
    this.bgElementsComponents.forEach(bgElementComponent => {
      if (bgElementComponent.bgElement.scale > 0.8) {
        bgElementComponent.onBgElementClick();
      }
      else if (bgElementComponent.bgElement.scale > 0.6 && bgElementComponent.bgElement.scale <= 0.8) {
        setTimeout(() => {
          bgElementComponent.onBgElementClick();
        }, 3000);
      }
      else {
        setTimeout(() => {
          bgElementComponent.onBgElementClick();
        }, 6000);
      }
    });
  }

  trackByFn(index) {
    return index;
  }
}
