import {Point} from './Point';

export class Shape {
  sides: number;
  points: Array<Point>;
  cx: number;
  cy: number;
  radius: number;


  constructor(sides, cx, cy, radius, statTypeArray?) {
    this.sides = sides;
    this.cx = cx;
    this.cy = cy;
    this.radius = radius;
    this.points = [];

    const dataPoints = [];

    if (statTypeArray === undefined) {
      for (let i = 0; i < this.sides; i++) {
        dataPoints.push(100);
      }
    }

    else {
      statTypeArray.forEach(stat => {
        dataPoints.push(stat);
      });
    }

    this.distributePoints(this.sides, dataPoints);
  }

  distributePoints(length: number, data: Array<number>) {
    for (let i = 0; i < length; i++) {
      this.points.push(new Point(this.sides, i, this.cx, this.cy, this.radius, data[i]));
    }
  }
}

