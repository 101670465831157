import {ChangeDetectionStrategy, Component, HostBinding, HostListener, Input, OnInit} from '@angular/core';
import {SharedFunctions} from '../../../shared/shared-functions';
import {GlobalService} from '../../../shared/services/global.service';
import {CONSTANTS} from '../../../shared/constants';

@Component({
  selector: 'app-bg-element',
  templateUrl: './bg-element.component.html',
  styleUrls: ['./bg-element.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BgElementComponent implements OnInit {
  @Input() bgElement: { x: number, y: number, scale: number };

  @HostBinding('style.margin') margin = '';
  @HostBinding('style.transform') transform = '';
  @HostBinding('class') hostClass = '';

  constructor(private globalService: GlobalService) {
  }

  ngOnInit() {
    this.update();
  }


  update(): void {
    const marginX = this.bgElement.y + 'vw';
    const marginY = this.bgElement.x + 'vh';
    this.margin = marginY + ' ' + marginX;
    this.transform = 'scale(' + this.bgElement.scale + ')';
  }

  @HostListener('click')
  onBgElementClick(): void {
    if (!this.globalService.isDeviceMobile) {
      this.bgElement.x = SharedFunctions.getRandomNumberFromTo(0, 100);
      this.bgElement.y = SharedFunctions.getRandomNumberFromTo(0, 100);

      this.update();
      if (this.hostClass === CONSTANTS.BG_ELEMENT_FORM_TYPE.Circle) {
        this.hostClass = CONSTANTS.BG_ELEMENT_FORM_TYPE.Square;
      }
      else {
        this.hostClass = CONSTANTS.BG_ELEMENT_FORM_TYPE.Circle;
      }
    }
  }


}
