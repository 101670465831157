/**
 * Created by XamiG on 2/6/2018.
 */

export class CONSTANTS {
  static MIN_DESKTOP_RATIO = 13 / 10;
  /** SAME AS in the media queries in the less files
   * ENSURE that they have the same value**/



  static SHAPES_FILL = [
    'rgba(9, 62, 79, 0.5)',
    'rgba(9, 62, 79, 0.5)',
    'rgba(9, 62, 79, 0.5)',
    'rgba(9, 62, 79, 0.5)'
  ];
  static SHAPES_STROKE = [
    '#f4f4f4',
    '#f4f4f4',
    '#f4f4f4',
    '#f4f4f4'];
  static STROKE_BETWEEN_SECTORS = '#f4f4f4';
  static LINE_WIDTH_BETWEEN_SECTORS = 2;
  static DATA_LINE_WIDTH = 2;

  static LINE_WIDTHS = [4, 2, 2, 2, 2, 2, 2, 2];

  static DATA_FILL = [
    'rgba(22, 164, 168, 0.7)',
    'rgba(110,31,168,0.7)',
  ];

  static DATA_STROKE = [
    '#1ae1e6',
    '#6e1fa8',
  ];

  static BG_ELEMENT_FORM_TYPE = {
    Square: '',
    Circle: 'circle',
  };

  static GAME_PREPARE_LOADING_MILLISECONDS = 3000;
  static IQ_TWEEN_MILLISECONDS = 2000;
}
