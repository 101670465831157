import {UiConfigs} from './ui-configs.model';
import {DailyChallengeGameSerializer} from './daily-challenge-game.serializer';
import {SharedFunctions} from '../shared-functions';
import {environment} from '../../../environments/environment';
import {IqConfigs} from './iq-configs.model';

/**
 * Created by XamiG on 2/12/2018.
 */

export class DailyChallengeGame {
  gameIdentifier: string;
  modeIdentifier: string;

  gameNameKey: string;
  modeNameKey: string;
  descriptionKey: string;

  linkUrl: string;

  iqConfigs: IqConfigs;
  uiConfigs: UiConfigs;

  generatedGameParams: any;

  // isReplayable: boolean;


  static create(obj: DailyChallengeGameSerializer) {
    const result = new this();
    Object.assign(result, SharedFunctions.serializeToTypeScriptConvention(obj, true));

    const uiConfigs = JSON.parse(JSON.stringify(result.uiConfigs));
    uiConfigs.iconUrl = uiConfigs.iconUrl ? `${environment.main_url}${uiConfigs.iconUrl }` : null;
    uiConfigs.videoUrl = uiConfigs.videoUrl ? `${environment.main_url}${uiConfigs.videoUrl }` : null;
    uiConfigs.logoUrl = uiConfigs.logoUrl ? `${environment.main_url}${uiConfigs.logoUrl }` : null;
    uiConfigs.gameScreenBgUrl = uiConfigs.gameScreenBgUrl ? `${environment.main_url}${uiConfigs.gameScreenBgUrl }` : null;
    uiConfigs.gameScreenHeaderBgUrl = uiConfigs.gameScreenHeaderBgUrl ? `${environment.main_url}${uiConfigs.gameScreenHeaderBgUrl }` : null;
    uiConfigs.gameScreenContentBgUrl = uiConfigs.gameScreenContentBgUrl ? `${environment.main_url}${uiConfigs.gameScreenContentBgUrl }` : null;

    result.uiConfigs = new UiConfigs();
    Object.assign(result.uiConfigs, uiConfigs);


    const iqConfigs = result.iqConfigs;
    result.iqConfigs = new IqConfigs();
    Object.assign(result.iqConfigs, iqConfigs);
    return result;
  }

  calculateIq(statistics: {
    speed: number,
    accuracy: number,
    analyticalSkills: number,
    calculation: number
  }): number {
    return ((this.iqConfigs.speedWeight / 100) * this.iqConfigs.maxIq) * statistics.speed +
      ((this.iqConfigs.accuracyWeight / 100) * this.iqConfigs.maxIq) * statistics.accuracy +
      ((this.iqConfigs.analyticalSkillsWeight / 100) * this.iqConfigs.maxIq) * statistics.analyticalSkills +
      ((this.iqConfigs.calculationWeight / 100) * this.iqConfigs.maxIq) * statistics.calculation || 0;
  }

}
